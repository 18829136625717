import React from 'react';
import type { FC } from 'react';
import type { Theme } from '@material-ui/core';
import type { SxProps } from '@material-ui/system';

interface LogoProps {
  sx?: SxProps<Theme>;
}

const Logo: FC<LogoProps> = () => (
  <img
    alt="commandbar logo"
    src="https://staticassets.commandbar.com/brand/logo_small.png"
    width={32}
  />
);

export default Logo;
