import { createAction, nanoid } from '@reduxjs/toolkit';
import { Company } from '../../types/company';

export const createCompany = createAction(
  'companies/createCompany',
  function prepare(company: Company) {
    const id = nanoid();

    return { payload: { company: { ...company, id } } };
  }
);

export const updateCompany = createAction(
  'companies/updateCompany',
  function prepare(company: Company) {
    return { payload: { company } };
  }
);

export const deleteCompanyById = createAction(
  'companies/deleteCompanyById',
  function prepare(companyId: string) {
    return { payload: { companyId } };
  }
);

export const bulkDeleteCompaniesById = createAction(
  'companies/bulkDeleteCompaniesById',
  function prepare(companyIds: string[]) {
    return { payload: { companyIds } };
  }
);
