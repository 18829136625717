import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Button,
} from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import type { AppBarProps } from '@material-ui/core';
import MenuIcon from '../../icons/Menu';
import { CommandBarLauncher } from './CommandBarLauncher';
import {
  SIGNUP_SEARCH_PARAM_NAME,
  SIGNUP_SEARCH_PARAM_VALUE,
} from '../../pages/IntroModal';
import { useSnackbar } from 'notistack';

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  zIndex: theme.zIndex.drawer + 100,
  fontFamily: theme.typography.fontFamily,
}));

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const { enqueueSnackbar } = useSnackbar();

  const handleShareButtonPress = () => {
    const url = new URL(window.location.href);

    if (!url.searchParams.has(SIGNUP_SEARCH_PARAM_NAME)) {
      url.searchParams.append(
        SIGNUP_SEARCH_PARAM_NAME,
        SIGNUP_SEARCH_PARAM_VALUE
      );
    }

    navigator.clipboard.writeText(url.toString());
    enqueueSnackbar('Shareable link copied to clipboard 📋', {
      variant: 'success',
    });
  };

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar
        sx={{
          height: 64,
          background:
            'linear-gradient(298.54deg, #2755f0 2.97%, #1042E5 100%, #5277F3 100%)',
        }}
      >
        <Hidden lgUp>
          <IconButton sx={{ color: '#fff' }} onClick={onSidebarMobileOpen}>
            <MenuIcon fontSize="small" />
          </IconButton>
        </Hidden>
        <Hidden lgDown>
          <RouterLink to="/" style={{ textDecoration: 'none' }}>
            <Box>
              <Box
                sx={{
                  color: 'white',
                  fontWeight: 600,
                  fontSize: 20,
                }}
              >
                Salesfarce
              </Box>
              <Box
                sx={{
                  color: 'white',
                  fontWeight: 400,
                  fontSize: 12,
                  opacity: 0.7,
                }}
              >
                CommandBar Demo App
              </Box>
            </Box>
          </RouterLink>
        </Hidden>

        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <Box>
          <Button
            title="Share"
            variant="outlined"
            onClick={() => handleShareButtonPress()}
            sx={{
              background:
                'linear-gradient(0deg, rgba(39, 85, 240, 0.2), rgba(39, 85, 240, 0.2)), #FFFFFF',
              color: '#2755f0',
              '&:hover': {
                background:
                  'linear-gradient(0deg, rgba(39, 85, 240, 0.3), rgba(39, 85, 240, 0.3)), #FFFFFF',
              },
            }}
          >
            Share
          </Button>
        </Box>
        <Box sx={{ ml: 2 }}>
          <CommandBarLauncher />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
