import { createSlice, nanoid } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { initialEvents } from './initialEvents';
import { CalendarEvent } from '../../types/calendar';
import { cloneDeep } from '../../utils/cloneDeep';

interface CalendarState {
  events: CalendarEvent[];
  selectedEventId: string | null;
  selectedRange: {
    start: number;
    end: number;
  } | null;
}

const initialState: CalendarState = {
  events: cloneDeep(initialEvents),
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getEvents(
      state: CalendarState,
      action: PayloadAction<{ events: CalendarEvent[] }>
    ): void {
      const { events } = action.payload;

      state.events = events;
    },
    createEvent: {
      reducer: (
        state: CalendarState,
        action: PayloadAction<{ event: CalendarEvent }>
      ) => {
        const { event } = action.payload;

        state.events.push(event);
      },
      prepare: (event: CalendarEvent) => {
        const newEvent = { id: nanoid(), ...event };

        return {
          payload: {
            event: cloneDeep(newEvent),
          },
        };
      },
    },
    selectEvent(
      state: CalendarState,
      action: PayloadAction<{ eventId?: string }>
    ): void {
      const { eventId = null } = action.payload;

      state.selectedEventId = eventId;
    },
    updateEvent: {
      reducer: (
        state: CalendarState,
        action: PayloadAction<{ update: Partial<CalendarEvent> }>
      ) => {
        const { update } = action.payload;

        state.events = state.events.map((_event) => {
          if (_event.id === update.id) {
            return { ..._event, ...update };
          }

          return _event;
        });
      },
      prepare: (update: Partial<CalendarEvent>) => {
        return {
          payload: {
            update: cloneDeep(update),
          },
        };
      },
    },
    deleteEvent(
      state: CalendarState,
      action: PayloadAction<{ eventId: string }>
    ): void {
      const { eventId } = action.payload;

      state.events = state.events.filter((event) => event.id !== eventId);
    },
    selectRange: {
      reducer: (
        state: CalendarState,
        action: PayloadAction<{ start: number; end: number }>
      ) => {
        const { start, end } = action.payload;

        state.selectedRange = {
          start,
          end,
        };
      },
      prepare: (start: Date, end: Date) => {
        return {
          payload: {
            start: start.getTime(),
            end: end.getTime(),
          },
        };
      },
    },
    closeModal(state: CalendarState): void {
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
});

export const { reducer } = slice;

export const {
  getEvents,
  closeModal,
  createEvent,
  updateEvent,
  deleteEvent,
  selectEvent,
  selectRange,
} = slice.actions;

export default slice;
