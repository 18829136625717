import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { StageColumn } from '../../types/stages';
import { entityFromArray } from '../../utils/entity';
import { populatedStageColumns } from './initialStageColumns';
import { getCompanyStageId } from './helpers';
import {
  bulkDeleteCompaniesById,
  createCompany,
  deleteCompanyById,
  updateCompany,
} from '../companies/actions';
import { Company } from '../../types/company';
import { moveCard } from './actions';

export interface StagesState {
  columns: {
    byId: Record<string, StageColumn>;
    allIds: string[];
  };
}

const initialState: StagesState = {
  columns: entityFromArray(populatedStageColumns),
};

const slice = createSlice({
  name: 'stages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(moveCard, (state, action) => {
        const { companyId, position, columnId } = action.payload;
        const sourceColumnId = getCompanyStageId(companyId)(state);

        state.columns.byId[sourceColumnId].companyIds = state.columns.byId[
          sourceColumnId
        ].companyIds.filter((_companyId) => _companyId !== companyId);

        if (columnId) {
          state.columns.byId[columnId].companyIds.splice(
            position,
            0,
            companyId
          );
        } else {
          state.columns.byId[sourceColumnId].companyIds.splice(
            position,
            0,
            companyId
          );
        }
      })
      .addCase(
        createCompany,
        (state, action: PayloadAction<{ company: Company }>) => {
          const { company } = action.payload;

          state.columns.byId[company.stageId].companyIds.push(company.id);
        }
      )
      .addCase(
        updateCompany,
        (state, action: PayloadAction<{ company: Company }>) => {
          const { company } = action.payload;
          const prevStageId = getCompanyStageId(company.id)(state);

          if (prevStageId !== company.stageId) {
            state.columns.byId[prevStageId].companyIds = state.columns.byId[
              prevStageId
            ].companyIds.filter((companyId) => companyId !== company.id);

            state.columns.byId[company.stageId].companyIds.push(company.id);
          }
        }
      )
      .addCase(
        deleteCompanyById,
        (state, action: PayloadAction<{ companyId: string }>) => {
          const { companyId: companyIdToDelete } = action.payload;
          const stageId = getCompanyStageId(companyIdToDelete)(state);

          state.columns.byId[stageId].companyIds = state.columns.byId[
            stageId
          ].companyIds.filter((companyId) => companyId !== companyIdToDelete);
        }
      )
      .addCase(
        bulkDeleteCompaniesById,
        (state, action: PayloadAction<{ companyIds: string[] }>) => {
          const { companyIds } = action.payload;

          companyIds.forEach((companyIdToDelete) => {
            const stageId = getCompanyStageId(companyIdToDelete)(state);

            state.columns.byId[stageId].companyIds = state.columns.byId[
              stageId
            ].companyIds.filter((companyId) => companyId !== companyIdToDelete);
          });
        }
      );
  },
});

export const { reducer } = slice;

export default slice;
