import objFromArray from './objFromArray';
import { Entity } from '../types/entity';

export const entityFromArray = <T extends { id: string }>(arr: T[]) => {
  const byId = objFromArray(arr);
  const allIds = arr.map(({ id }) => id);

  return { byId, allIds };
};

export const arrayFromEntity = <T>(entity: Entity<T>) =>
  entity.allIds.map((id) => entity.byId[id]);
