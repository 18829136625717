import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Typography,
  styled,
} from '@material-ui/core';
import { Apps as AppsIcon } from '@material-ui/icons';
import CalendarIcon from '../../icons/Calendar';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import ClipboardListIcon from '../../icons/ClipboardList';
import UserIcon from '../../icons/User';
import UsersIcon from '../../icons/Users';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import { ThemeSwitch } from '../ThemeSwitch';
import useSettings from '../../hooks/useSettings';
import { THEMES } from '../../constants';
import { useSelector } from '../../store';
import { Avatar } from '../Avatar';
import { ShortcutTooltipContent } from '../ShortcutTooltipContent';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const sections = [
  {
    title: 'Navigation',
    items: [
      {
        title: 'Overview',
        path: '/dashboard',
        icon: <ChartSquareBarIcon fontSize="small" />,
        tooltip: <ShortcutTooltipContent keys={['G', 'O']} />,
      },
      {
        title: 'Leads',
        path: '/leads',
        icon: <UsersIcon fontSize="small" />,
        tooltip: <ShortcutTooltipContent keys={['G', 'L']} />,
      },
      {
        title: 'Pipeline',
        path: '/pipeline',
        icon: <ClipboardListIcon fontSize="small" />,
        tooltip: <ShortcutTooltipContent keys={['G', 'P']} />,
      },
      {
        title: 'Calendar',
        path: '/calendar',
        icon: <CalendarIcon fontSize="small" />,
        tooltip: <ShortcutTooltipContent keys={['G', 'C']} />,
      },
    ],
  },
  {
    title: 'Settings',
    items: [
      {
        title: 'Integrations',
        path: '/integrations',
        icon: <AppsIcon fontSize="small" />,
        tooltip: <ShortcutTooltipContent keys={['G', 'I']} />,
      },
      {
        title: 'Account',
        path: '/account/general',
        icon: <UserIcon fontSize="small" />,
        tooltip: <ShortcutTooltipContent keys={['G', 'A']} />,
      },
    ],
  },
];

const StyledBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.primary.main,
  textAlign: 'center',
}));

const DashboardSidebar = ({
  onMobileClose,
  openMobile,
}: DashboardSidebarProps) => {
  const location = useLocation();
  const { name, logoURL } = useSelector((state) => state.accountSettings);
  const { settings, saveSettings } = useSettings();

  const handleThemeChange = (value: boolean) => {
    const newTheme = value ? THEMES.DARK : THEMES.LIGHT;

    saveSettings({ ...settings, theme: newTheme });
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2,
            }}
          >
            <StyledBox>
              <Box sx={{ fontWeight: 600, fontSize: 20 }}>Salesfarce</Box>
              <Box
                sx={{
                  fontWeight: 400,
                  fontSize: 12,
                  opacity: 0.7,
                }}
              >
                CommandBar Demo App
              </Box>
            </StyledBox>
          </Box>
        </Hidden>
        <Box sx={{ p: 1.5 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 1,
            }}
          >
            <RouterLink to="/account/general">
              <Avatar
                str={logoURL}
                size={40}
                sx={{
                  cursor: 'pointer',
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {name}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                Your plan: Pro
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
      </Scrollbar>
      <Box
        sx={{
          display: 'flex',
          p: 1,
          pb: 9,
          justifyContent: 'flex-end',
        }}
      >
        <ThemeSwitch
          checked={settings.theme === THEMES.DARK}
          onChange={(event, value) => handleThemeChange(value)}
        />
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#21212a' : 'background.paper',
              width: 280,
            },
          }}
          ModalProps={{
            disableEnforceFocus: true,
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#21212a' : 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280,
            },
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
