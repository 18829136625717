import React from 'react';
import { Switch, SwitchProps } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Nightlight, WbSunny } from '@material-ui/icons';

export const ThemeSwitch = styled((props: SwitchProps) => {
  const iconStyle = {
    fontSize: 17,
  };

  return (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      icon={<WbSunny sx={iconStyle} />}
      checkedIcon={<Nightlight sx={iconStyle} />}
      {...props}
    />
  );
})(({ theme }) => ({
  width: 60,
  px: 0,
  '& .MuiSwitch-switchBase': {
    top: 7,
    width: 25,
    height: 25,
    transform: 'translateX(4px)',
    backgroundColor: theme.palette.common.white,
    border: `1px solid rgba(0, 0, 0, 0.2)`,
    color: theme.palette.warning.light,

    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },

    '&.Mui-checked': {
      transform: 'translateX(28px)',
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.dark,
      border: `1px solid ${theme.palette.secondary.dark}`,

      '& + .MuiSwitch-track': {},
    },

    '&:hover.Mui-checked': {
      backgroundColor: theme.palette.background.default,
    },

    '&.Mui-focusVisible .MuiSwitch-thumb': {},
  },
  '& .MuiSwitch-thumb': {},
  '& .MuiSwitch-track': {
    backgroundColor: theme.palette.grey[400],
  },
}));
