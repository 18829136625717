import { StyledComponent } from '@emotion/styled';
import React from 'react';
import styled from 'styled-components';

export const HubspotForm = (props: {
  onSubmit: () => void;
  region: string;
  portalId: string;
  formId: string;
  styledComponent?: StyledComponent<any, any, any>;
}) => {
  React.useEffect(() => {
    (window as any).hbspt.forms.create({
      region: props.region,
      portalId: props.portalId,
      formId: props.formId,
      target: '#hbsptSignUpForm',

      onFormSubmitted: () => {
        props.onSubmit();
      },
    });
  }, []);

  return (
    <>
      {props.styledComponent ? (
        <props.styledComponent>
          <div id="hbsptSignUpForm"></div>
        </props.styledComponent>
      ) : (
        <HubspotFormContainer>
          <div id="hbsptSignUpForm"></div>
        </HubspotFormContainer>
      )}
    </>
  );
};

export const HubspotFormContainer = styled.div`
  form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 20px;
    & * {
      font-family: -apple-system, Avenir Next, proxima-nova, Helvetica, Arial,
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji' !important;
    }
    fieldset {
      width: 100%;
      max-width: none;
      gap: 8px;
      display: flex;
      flex-direction: row;

      & > div {
        flex: 1;
      }

      label {
      }
      legend {
      }

      input[type='checkbox'] {
        margin-right: 6px;
      }

      input[type='text'],
      input[type='email'],
      select {
        -webkit-text-size-adjust: 100%;
        tab-size: 4;
        border-style: solid;
        box-sizing: border-box;
        font-family: inherit;
        margin: 0;
        margin-top: 0.5rem;
        border-radius: 0.375rem;
        border-width: 2px;
        border-color: rgba(230, 234, 250, 1);
        background-color: rgba(240, 243, 253, 1);
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 1.125rem;
        line-height: 1.75rem;
        font-weight: 500;
        color: rgba(62, 62, 78, 1);
        outline-width: 0px;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 200ms;
        width: 100% !important;
      }
      ul {
        padding-top: 10px;
        margin-bottom: 0px;

        li {
          list-style: none;
        }
      }
    }

    .hs_submit {
      width: 100%;
      display: flex;
      justify-content: center;
      .actions {
        input {
          -webkit-text-size-adjust: 100%;
          tab-size: 4;
          border-style: solid;
          border-color: #e0e0e7;
          box-sizing: border-box;
          font-family: inherit;
          margin: 0;
          text-transform: none;
          cursor: pointer;
          -webkit-appearance: button;
          background-image: none;
          display: inline-flex;
          width: 20rem;
          align-items: center;
          justify-content: center;
          border-radius: 0.375rem;
          border-width: 0px;
          background-color: rgba(61, 101, 230, 1);
          padding-left: 1rem;
          padding-right: 1rem;
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
          font-size: 1.125rem;
          line-height: 1.75rem;
          font-weight: 500;
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
`;
