import { StageColumn } from '../../types/stages';
import { companiesList } from '../companies/companiesList';

export const initialStageColumns: StageColumn[] = [
  {
    id: '1',
    name: 'Lead generated',
    companyIds: [],
  },
  {
    id: '2',
    name: 'Contacted',
    companyIds: [],
  },
  {
    id: '3',
    name: 'Qualified',
    companyIds: [],
  },
  {
    id: '4',
    name: 'Trial completed',
    companyIds: [],
  },
  {
    id: '5',
    name: 'Contract negotiation',
    companyIds: [],
  },
  {
    id: '6',
    name: 'Post-sale',
    companyIds: [],
  },
  {
    id: '7',
    name: 'Lost',
    companyIds: [],
  },
];

export const populatedStageColumns: StageColumn[] = initialStageColumns.map(
  (stage) => {
    const companyIds = companiesList.reduce((acc, company) => {
      return company.stageId === stage.id ? [...acc, company.id] : acc;
    }, []);

    return {
      ...stage,
      companyIds,
    };
  }
);
