import { RootState } from '../../store';
import { CompanyNote } from '../../types/company';
import { compareAsc, compareDesc, parseISO } from 'date-fns';

export const getCompaniesSelector = (state: RootState) =>
  state.companies.entity.allIds.map((companyId) =>
    getCompanyByIdSelector(companyId)(state)
  );

export const getCompanyByIdSelector = (id: string) => (state: RootState) =>
  state.companies.entity.byId[id];

export const getCompanyByNoteId = (noteId: string) => (state: RootState) =>
  getCompaniesSelector(state).find(
    (company) => company.notes.findIndex((note) => note.id === noteId) !== -1
  );

export const getRecentNotes =
  (limit = 4) =>
  (state: RootState): CompanyNote[] => {
    const notes = getCompaniesSelector(state)
      .reduce((acc, company) => [...acc, ...company.notes], [])
      .sort((left, right) => {
        const leftDate = parseISO(left.updatedAt);
        const rightDate = parseISO(right.updatedAt);

        return compareDesc(leftDate, rightDate);
      });

    return notes.slice(0, limit);
  };

export const getAllCompanyLabels = (state: RootState) => state.companies.labels;

export const getCompaniesWithRemindersSelector =
  (limit = 5) =>
  (state: RootState) => {
    const companies = getCompaniesSelector(state)
      .filter(({ reminder }) => reminder)
      .sort((left, right) => {
        const leftDate = parseISO(left.reminder);
        const rightDate = parseISO(right.reminder);

        return compareAsc(leftDate, rightDate);
      });

    return companies.slice(0, limit);
  };
