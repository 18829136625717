import React, { CSSProperties } from 'react';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import {
  Box,
  Button,
  Card,
  LinearProgress as MuiLinearProgress,
  linearProgressClasses,
  styled,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from '../../store';
import {
  completeTask,
  getOnboardingTasks,
  isCurrentTask,
} from '../../slices/onboardingTasks';
import { Check, KeyboardArrowUpOutlined } from '@material-ui/icons';

import { useLocation } from 'react-router-dom';

import './animations.css';

import useWindowSize from 'src/hooks/useWindowSize';
import SupportModal from './SupportModal';

const LinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}));

const LOCALSTORAGE_KEY = 'onboarding.minimized';

const isMinimized = () => {
  const value = localStorage.getItem(LOCALSTORAGE_KEY);

  if (value === null) {
    return false;
  }

  return JSON.parse(value);
};

export const OnboardingChecklist = () => {
  const [minimized, setMinimized] = React.useState(isMinimized());
  const [supportOpen, setSupportOpen] = React.useState(false);
  const [_dummy, setDummy] = React.useState(false);
  const { pathname } = useLocation();
  const tasks = useSelector(getOnboardingTasks);
  const dispatch = useDispatch();

  const windowWidth = useWindowSize().width;

  React.useEffect(() => {
    window.localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(minimized));
  }, [minimized]);

  // Crude way to re-render checklist for steps. Should replace with
  //   a timeout dispatch to turn off 'recentlyCompleted'
  const REFRESH_INTERVAL = 500;
  React.useEffect(() => {
    const id = setInterval(
      () => setDummy((prevState) => !prevState),
      REFRESH_INTERVAL
    );

    return () => {
      clearInterval(id);
    };
  }, []);

  const incompleteTasks = tasks.filter(({ completed }) => completed);
  const progress = (incompleteTasks.length / tasks.length) * 100;
  const currentTask = tasks.find(isCurrentTask);

  const isCommandBarOpen = !!window.CommandBar?.isOpen();
  const COMMANDBAR_CHECKLIST_OVERLAP_SCREEN_BREAKPOINT = 1300;
  const COMMANDBAR_CHECKLIST_OVERLAP_SCREEN_BREAKPOINT2 = 1050;
  const isNormalScreen =
    windowWidth > COMMANDBAR_CHECKLIST_OVERLAP_SCREEN_BREAKPOINT ||
    !isCommandBarOpen;
  const isMiniScreen =
    !isNormalScreen &&
    windowWidth > COMMANDBAR_CHECKLIST_OVERLAP_SCREEN_BREAKPOINT2;

  const screenStyles: CSSProperties = {
    right: 15,
    bottom: pathname.includes('/leads/') ? 15 : 70,
    width: 350,
  };

  const miniScreenStyles: CSSProperties = {
    right: 15,
    bottom: pathname.includes('/leads/') ? 15 : 70,
    width: (windowWidth - 575) / 2,
  };

  const smallScreenStyles: CSSProperties = {
    right: 10,
    bottom: 10,
    width: windowWidth - 20,
  };

  return (
    <>
      <Card
        elevation={24}
        sx={{
          position: 'fixed',
          px: 2,
          pt: 1,
          pb: 3,
          background: (theme) =>
            theme.palette.mode === 'dark' ? '#3e3e4a' : '#fafafa',
          borderRadius: 4,
          maxHeight: minimized ? 90 : 'auto',
          transition: 'all 0.2s ease-in-out, background 0s, color 0s',
          border: '3px solid #2755f070',
          boxShadow: '0px 4px 12px rgba(35, 35, 41, 0.3)',
          zIndex: 1301,
          ...(isNormalScreen
            ? screenStyles
            : isMiniScreen
            ? miniScreenStyles
            : smallScreenStyles),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          onClick={() => setMinimized((prevState) => !prevState)}
        >
          <Typography
            variant={'h6'}
            sx={{ p: 1, display: 'flex', alignItems: 'center' }}
          >
            Instructions
            <KeyboardArrowUpOutlined
              style={{
                cursor: 'pointer',
                opacity: 0.5,
                transform: minimized ? 'scale(1,1)' : 'scale(1,-1)',
                transition: 'all 0.2s ease-in',
              }}
              fontSize="medium"
            />
          </Typography>
          <Typography
            sx={{ p: 1 }}
            style={{ fontSize: '12px', cursor: 'pointer', opacity: 0.6 }}
            onClick={(e) => {
              setSupportOpen(true);
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            Need help?{' '}
          </Typography>
        </Box>
        <LinearProgress
          value={progress}
          variant="determinate"
          sx={{ m: 1, mb: 2 }}
        />
        <ReactCSSTransitionReplace
          transitionName="cross-fade"
          transitionEnterTimeout={1000}
          transitionLeaveTimeout={1000}
        >
          {!!currentTask ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                pl: 1,
                pr: 1,
              }}
              key={currentTask.id}
            >
              {currentTask.title && (
                <Box
                  sx={{ display: 'flex', pb: 2, pt: 1, alignItems: 'center' }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 600,
                      opacity: 0.8,
                    }}
                  >
                    {currentTask.title}
                  </Typography>

                  {currentTask.completed && (
                    <Check
                      className="complete-check-entry"
                      sx={{ ml: 1, color: '#24be85', fontSize: 14 }}
                    />
                  )}
                </Box>
              )}
              <Typography variant="body2">{currentTask.content}</Typography>
              {currentTask.allowNext && (
                <Box
                  sx={{ pt: 1, display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Button
                    onClick={() => dispatch(completeTask(currentTask.id))}
                  >
                    Next
                  </Button>
                </Box>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: 'auto',
              }}
              key={'completed'}
            >
              <Typography variant="body2">
                <div>
                  <span>
                    {`That's all we have for you! If you want to learn more about
                    CommandBar, explore our `}
                  </span>
                  <a
                    href="https://www.commandbar.com"
                    style={{ color: 'inherit' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    site
                  </a>
                  <span>.</span>
                </div>
              </Typography>
            </Box>
          )}
        </ReactCSSTransitionReplace>
      </Card>
      <SupportModal open={supportOpen} onClose={() => setSupportOpen(false)} />
    </>
  );
};
