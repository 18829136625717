import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Integration } from '../types/integrations';
import { RootState } from '../store';

type IntegrationsState = Integration[];

const initialState: IntegrationsState = [
  {
    name: 'Slack',
    subtitle: 'Keep your team in the loop with CRM changes.',
    description:
      'Slack is a workplace collaboration tool that can also send and receive event notifications. You can configure notifications to be send whenever a lead is edited or a pipeline change occurs.',
    logo: '/static/images/integrations/slack.png',
    isActive: true,
  },
  {
    name: 'JIRA',
    subtitle: 'The top software used by agile development teams.',
    description:
      'Integrate product updates into your lead management workflow. Tag features with leads and customers that have asked for them, and trigger automated updates when these features are completed.',
    logo: '/static/images/integrations/jira.png',
    isActive: false,
  },
  {
    name: 'Salesforce',
    subtitle:
      'Integrate the leader in on-demand customer relationship management.',
    description:
      'Skip the standard process of waiting for a Salesforce Administrator to help format and export your data (all the while trying to upsell you modules). Using the Salesforce integration, you can automatically export your Salesforce data, as well as push changes when you make edits to a lead.',
    logo: '/static/images/integrations/salesforce.png',
    isActive: true,
  },
  {
    name: 'Hubspot',
    subtitle: 'Connect email automation tools to your lead management',
    description:
      'You have email automation workflows already built out, and want to sync them to your source of truth for lead management. Syncing prospects and leads into HubSpot means uploading large CSVs... or turning on the one-click integration below.',
    logo: '/static/images/integrations/hubspot.png',
    isActive: false,
  },
];

const slice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    toggleIntegration: {
      reducer: (state, action: PayloadAction<{ name: string }>) => {
        const { name } = action.payload;
        const index = state.findIndex(
          (integration) => integration.name.toLowerCase() === name.toLowerCase()
        );

        if (index !== -1) {
          state[index].isActive = !state[index].isActive;
        }
      },
      prepare: (name: string) => {
        return { payload: { name } };
      },
    },
  },
});

export const getIntegrationByNameSelector =
  (name: string) => (state: RootState) => {
    const index = state.integrations.findIndex(
      (integration) => integration.name.toLowerCase() === name.toLowerCase()
    );

    if (index !== -1) {
      return state.integrations[index];
    }

    throw new Error(`Unable to get ${name} integration`);
  };

export const { reducer } = slice;
export const { toggleIntegration } = slice.actions;

export default slice;
