import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { identifyUserEmail } from '../../identify';

import Cohere from 'cohere-js';

type CallStatus = 'closed' | 'dialing' | 'missed' | 'inCall' | 'ended';

const SupportModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [callState, setCallState] = useState<CallStatus>('closed');
  const [email, setEmail] = useState<string | null>(null);

  useEffect(() => {
    const callListener = (status: CallStatus) => {
      // Don't reset call state if missed, so user can put in email
      if (callState === 'missed') return;
      setCallState(status);
    };
    Cohere.addCallStatusListener(callListener);
    return () => {
      Cohere.removeCallStatusListener(callListener);
    };
  }, []);

  const handleClose = () => {
    onClose();
    setTimeout(() => setCallState('closed'), 200);
  };

  let CTA;
  switch (callState) {
    case 'closed':
      CTA = (
        <Button
          onClick={() => Cohere.makeCall()}
          color="primary"
          variant="contained"
          sx={{ width: '100%' }}
        >
          Talk now
        </Button>
      );
      break;
    case 'dialing':
      CTA = (
        <Button
          color="primary"
          variant="contained"
          sx={{ width: '100%' }}
          disabled
        >
          <CircularProgress size={12} sx={{ mr: 1 }} /> Calling team...
        </Button>
      );
      break;
    case 'missed':
      break;
    case 'inCall':
      CTA = (
        <Button
          color="primary"
          variant="contained"
          sx={{ width: '100%' }}
          disabled
        >
          Call in progress
        </Button>
      );
      break;
    case 'ended':
      CTA = (
        <Typography variant="body2">
          Thank you! The team will reach out to you shortly.
        </Typography>
      );
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      style={{ zIndex: 1400 }}
      maxWidth="xs"
    >
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          pt: 3,
          pb: 3,
          pl: 1,
          pr: 1,
        }}
      >
        {callState === 'missed' ? (
          <Container>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              mb={1}
            >
              <Grid item>
                <Typography color="textPrimary" variant="h6">
                  Need help?
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              {`Sorry it looks like we're unavailable. Please leave your email below and we'll get back to you!`}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                pt: 2,
              }}
            >
              <TextField
                label="email"
                onChange={(e) => setEmail(e.target.value)}
                sx={{ flexGrow: 1 }}
              />
              <Button
                onClick={() => {
                  identifyUserEmail(email);
                  setCallState('ended');
                }}
                sx={{ width: '100px' }}
                disabled={!email}
              >
                Submit
              </Button>
            </Box>
          </Container>
        ) : (
          <Container>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              mb={1}
            >
              <Grid item>
                <Typography color="textPrimary" variant="h6">
                  Need help?
                </Typography>
              </Grid>
            </Grid>
            <Box mt={2}>
              <Typography color="textPrimary" variant="body2">
                {`We're here to help out! Click the button below to talk with a member of the CommandBar team.`}
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography
                color="textPrimary"
                variant="body2"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '12px',
                  fontWeight: 600,
                  opacity: 0.4,
                }}
              >
                <AccessTimeIcon sx={{ fontSize: '12px', mr: 0.5 }} />
                Team typically picks up within 1 minute.
              </Typography>
            </Box>
            <Box sx={{ pt: 4 }}>{CTA}</Box>
          </Container>
        )}
      </Box>
    </Dialog>
  );
};

export default SupportModal;
