import Cohere from 'cohere-js';
import { nanoid } from '@reduxjs/toolkit';

export const identifyUser = () => {
  Cohere.init('o2wkykQNR4b7w7KDVb5H9Z3U');
  let uid = localStorage.getItem('uid');
  if (!uid) {
    uid = nanoid();
    localStorage.setItem('uid', uid);
  }
  Cohere.identify(uid, { displayName: uid });
  (window as any)?.hj('identify', uid);
  window.CommandBar.boot(uid);
};

export const identifyUserEmail = (email: string) => {
  const uid = localStorage.getItem('uid');
  Cohere.identify(uid, { email, displayName: uid });
  (window as any)?.hj('identify', uid, { email });
};
