import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';

type BillingPlan = {
  name: string;
  price: number;
  features: string[];
};

const plans: BillingPlan[] = [
  {
    name: 'Starter',
    price: 25,
    features: [
      'A Full CRM Solution',
      '2-Way Email Syncing',
      'Built-in video call',
    ],
  },
  {
    name: 'Basic',
    price: 50,
    features: [
      'Roles & Permissions',
      'Activity Comparison',
      'Custom Activities',
    ],
  },
  {
    name: 'Pro',
    price: 100,
    features: ['Bulk Email', 'Email Campaigns', 'Call Recording'],
  },
  {
    name: 'Business',
    price: 250,
    features: [
      '50,000 Zaps Per Month',
      'Custom Roles',
      'User Groups',
      'Group Numbers',
    ],
  },
];

const AccountBillingSettings = () => {
  const [open, setOpen] = React.useState(false);

  const handlePlanSelect = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card>
        <CardHeader title="Manage your plan" />
        <CardContent>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {plans.map((plan, index) => {
              return (
                <Card
                  key={plan.name}
                  sx={{
                    display: 'flex',
                    width: 245,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    p: 3,
                    backgroundColor: 'action.hover',
                    mr: 3,
                    mb: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: 200,
                    }}
                  >
                    <Typography variant="h5" sx={{ mb: 2 }}>
                      {plan.name}
                    </Typography>
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}
                    >
                      {plan.features.map((feature) => {
                        return (
                          <Box
                            key={feature}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              mb: 0.5,
                            }}
                          >
                            <CheckCircle
                              sx={{
                                color: 'success.main',
                                mr: 1,
                                fontSize: '1rem',
                              }}
                            />
                            <Typography>{feature}</Typography>
                          </Box>
                        );
                      })}
                    </Box>
                    {index !== 0 && (
                      <Typography
                        variant="caption"
                        sx={{
                          textTransform: 'uppercase',
                          py: 0.1,
                          px: 1,
                          mb: 1.5,
                          backgroundColor: (theme) =>
                            theme.palette.mode === 'dark'
                              ? 'rgba(255, 255, 255, 0.15)'
                              : 'rgba(0, 0, 0, 0.1)',
                          width: 'max-content',
                          borderRadius: 1,
                          fontWeight: '600',
                        }}
                      >
                        & {plans[index - 1].name} features
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                      height: 100,
                      width: '100%',
                    }}
                  >
                    <Typography variant="h6" sx={{ mb: -0.5 }}>
                      ${plan.price}
                    </Typography>
                    <Typography variant="body1" sx={{}}>
                      / user / month
                    </Typography>

                    <Button
                      disabled={index === 0}
                      variant="contained"
                      fullWidth
                      sx={{
                        mt: 1.5,
                        backgroundColor: (theme) =>
                          theme.palette.mode === 'dark'
                            ? 'success.dark'
                            : 'success.main',
                      }}
                      onClick={handlePlanSelect}
                    >
                      {index === 0 ? 'Your Current Plan' : 'Upgrade'}
                    </Button>
                  </Box>
                </Card>
              );
            })}
          </Box>
        </CardContent>
      </Card>
      <Dialog
        open={open}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Unable to change the billing plan
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Until your background checks come back clean, you
            cannot change your plan.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AccountBillingSettings;
