import React from 'react';
import { Box } from '@material-ui/core';
import 'commandbar-launcher/assets/index.css';
import Launcher from 'commandbar-launcher';

export const CommandBarLauncher = () => (
  <Box
    sx={{
      '& .commandbar-user-launcher': {
        cursor: 'pointer',
        backgroundColor: '#fff',
        border: (theme) => `1px solid ${theme.palette.divider}`,
        color: '#120B4C',
        transition: 'color 0s',

        '& .commandbar-user-launcher__prefix svg': {
          color: '#2755f0',
        },

        '& .commandbar-user-launcher__tag': {
          color: '#fff',
          backgroundColor: '#1F1C4B',
        },
      },
    }}
  >
    <Launcher />
  </Box>
);
