import React from 'react';
import { Box, Container, Dialog, Typography } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';

const BoldText = experimentalStyled('span')({
  fontWeight: 500,
});

const MobileModal = () => {
  return (
    <Dialog fullWidth maxWidth="md" open={true} style={{ zIndex: 1400 }}>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          pt: 5,
          pb: 5,
          pr: 3,
          pl: 3,
        }}
      >
        <Container>
          <Box>
            <Typography color="textPrimary" variant="h5">
              <BoldText>Not available on mobile</BoldText>
            </Typography>
          </Box>
          <Box mt={3}>
            <Typography color="textPrimary" variant="body1">
              This demo is optimized for desktop. Please try it out on a desktop
              browser to take CommandBar for a spin.
            </Typography>
          </Box>
        </Container>
      </Box>
    </Dialog>
  );
};

export default MobileModal;
