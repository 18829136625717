import React, { Suspense, lazy } from 'react';
import { PartialRouteObject, Navigate } from 'react-router';
import DashboardLayout from './components/dashboard/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import {
  AccountGeneralSettings,
  AccountNotificationsSettings,
  AccountBillingSettings,
} from './components/dashboard/account';

// eslint-disable-next-line react/display-name
const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Calendar = Loadable(lazy(() => import('./pages/dashboard/Calendar')));
const CalendarModal = Loadable(
  lazy(() => import('./pages/dashboard/CalendarModal'))
);
const CompanyEditModal = Loadable(
  lazy(() => import('./pages/dashboard/CompanyEditModal'))
);
const CompanyViewModal = Loadable(
  lazy(() => import('./pages/dashboard/CompanyViewModal'))
);
const CompaniesList = Loadable(
  lazy(() => import('./pages/dashboard/CompaniesList'))
);
const Stages = Loadable(lazy(() => import('./pages/dashboard/Stages')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const Integrations = Loadable(
  lazy(() => import('./pages/dashboard/Integrations'))
);
const IntegrationModal = Loadable(
  lazy(() => import('./pages/dashboard/IntegrationModal'))
);

// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import('./pages/AuthorizationRequired'))
);
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const routes: PartialRouteObject[] = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/leads" replace />,
      },
      {
        path: 'dashboard',
        element: <Overview />,
        children: [
          {
            path: 'preview',
            element: <Overview />,
            children: [
              {
                path: '/',
                element: <Navigate to="/dashboard" replace />,
              },
              {
                path: ':companyId',
                element: (
                  <CompanyViewModal onCloseRedirectTo="../.." disableEdit />
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'account',
        element: <Account />,
        children: [
          {
            path: '/',
            element: <Navigate to="general" replace />,
          },
          {
            path: 'general',
            element: <AccountGeneralSettings />,
          },
          {
            path: 'billing-plan',
            element: <AccountBillingSettings />,
          },
          {
            path: 'notifications',
            element: <AccountNotificationsSettings />,
          },
          { path: '*', element: <Navigate to="general" replace /> },
        ],
      },
      {
        path: 'calendar',
        element: <Calendar />,
        children: [
          {
            path: ':eventId',
            element: <CalendarModal />,
          },
          {
            path: 'new',
            element: <CalendarModal />,
          },
        ],
      },
      {
        path: 'leads',
        element: <CompaniesList />,
        children: [
          {
            path: ':companyId',
            element: <CompanyViewModal />,
          },
          {
            path: ':companyId/edit',
            element: <CompanyEditModal />,
          },
          {
            path: 'new',
            element: <CompanyEditModal />,
          },
        ],
      },
      {
        path: 'pipeline',
        element: <Stages />,
        children: [
          {
            path: ':companyId',
            element: <CompanyViewModal />,
          },
          {
            path: ':companyId/edit',
            element: <CompanyEditModal />,
          },
        ],
      },
      {
        path: 'integrations',
        element: <Integrations />,
        children: [
          {
            path: ':integrationName',
            element: <IntegrationModal />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
