import { createSlice, nanoid } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AccountSettings } from '../types/settings';

type AccountSettingsState = AccountSettings;

const initialState: AccountSettingsState = {
  id: nanoid(),
  name: 'Jim Halpert',
  email: 'jim@dundermifflin.com',
  phone: '+1234567890',
  country: 'United States',
  state: 'Pennsylvania',
  city: 'Scranton',
  logoURL:
    'https://staticassets.commandbar.com/showcase/jim-halpert-headshot.jpeg',

  emailNotifications: true,
  pushNotifications: true,
  textMessage: true,
  phoneCalls: true,
};

const slice = createSlice({
  name: 'accountSettings',
  initialState,
  reducers: {
    updateAccountSettings: {
      reducer: (
        state: AccountSettingsState,
        action: PayloadAction<{ accountSettings: Partial<AccountSettings> }>
      ) => {
        const { accountSettings } = action.payload;

        return { ...state, ...accountSettings };
      },
      prepare: (accountSettings: Partial<AccountSettings>) => {
        return {
          payload: {
            accountSettings,
          },
        };
      },
    },
  },
});

export const { reducer } = slice;

export const { updateAccountSettings } = slice.actions;

export default slice;
