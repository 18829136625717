import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import * as Yup from 'yup';
import { updateAccountSettings } from '../../../slices/accountSettings';
import { Formik } from 'formik';
import { useDispatch, useSelector } from '../../../store';

const AccountNotificationsSettings = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state) => state.accountSettings);

  useEffect(() => {
    window.CommandBar.addContext({
      notificationOptions: [
        { value: 'emailNotifications', label: 'Email Notifications' },
        { value: 'pushNotifications', label: 'Push Notifications' },
        { value: 'textMessage', label: 'Text Message' },
        { value: 'phoneCalls', label: 'Phone Calls' },
      ],
    });
    // helper method to update the notification settings
    const updateSettings = (notifications: any) => {
      console.log(notifications);
      const values = {
        emailNotifications: notifications['emailNotifications'],
        pushNotifications: notifications['pushNotifications'],
        textMessage: notifications['textMessage'],
        phoneCalls: notifications['phoneCalls'],
      };
      dispatch(updateAccountSettings(values));

      enqueueSnackbar('Profile updated', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        variant: 'success',
      });
    };

    // what we pass to CommandBar (just calls on updateSettings above)
    const setNotifications = (args: {
      notifications: [{ label: string; value: string }];
    }) => {
      console.log(args.notifications);
      updateSettings(
        args.notifications.reduce(function (obj, notification) {
          const { value } = notification;
          return { ...obj, [value]: true };
        }, {})
      );
    };
    window.CommandBar.addCallback('setNotifications', setNotifications);
  }, [account]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        emailNotifications: account.emailNotifications || false,
        pushNotifications: account.pushNotifications || false,
        textMessage: account.textMessage || false,
        phoneCalls: account.phoneCalls || false,
      }}
      validationSchema={Yup.object().shape({
        emailNotifications: Yup.bool(),
        pushNotifications: Yup.bool(),
        textMessage: Yup.bool(),
        phoneCalls: Yup.bool(),
      })}
      onSubmit={async (
        values,
        { resetForm, setStatus, setSubmitting }
      ): Promise<void> => {
        try {
          dispatch(updateAccountSettings(values));
          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar('Profile updated', {
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
            variant: 'success',
          });
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({ handleChange, handleSubmit, values }): JSX.Element => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader title="Notifications" />
            <CardContent>
              <Grid container spacing={6} wrap="wrap">
                <Grid item md={4} sm={6} xs={12}>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    System
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    You will receive emails in your business email address
                  </Typography>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name="emailNotifications"
                          checked={values.emailNotifications}
                          onChange={handleChange}
                        />
                      }
                      label="Email alerts"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name="pushNotifications"
                          checked={values.pushNotifications}
                          onChange={handleChange}
                        />
                      }
                      label="Push Notifications"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name="textMessage"
                          checked={values.textMessage}
                          onChange={handleChange}
                        />
                      }
                      label="Text message"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name="phoneCalls"
                          checked={values.phoneCalls}
                          onChange={handleChange}
                        />
                      }
                      label={
                        <>
                          <Typography color="textPrimary" variant="body1">
                            Phone calls
                          </Typography>
                        </>
                      }
                    />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2,
              }}
            >
              <Button color="primary" type="submit" variant="contained">
                Save Settings
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default AccountNotificationsSettings;
