import React from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { OnboardingTask } from '../types/onboarding';
import { osControlKey } from '@commandbar/internal/util/operatingSystem';
import { Box } from '@material-ui/core';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';

import { experimentalStyled } from '@material-ui/core/styles';

// eslint-disable-next-line unused-imports/no-unused-vars
const ExtraEmphasisText = experimentalStyled('span')(({ theme }) => ({
  fontWeight: 600,
}));

// eslint-disable-next-line unused-imports/no-unused-vars
const EmphasisText = experimentalStyled('span')(({ theme }) => ({
  fontWeight: 500,
}));

type OnboardingTasksState = OnboardingTask[];

// eslint-disable-next-line react/prop-types
const Instruction = ({ children }) => {
  return (
    <Box
      sx={{
        p: 1.5,
        borderRadius: 2,
        mt: 1,
        background: '#2755f0cc',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <OfflineBoltIcon sx={{ transform: 'rotate(15deg)', mr: 1 }} />
      <div>{children}</div>
    </Box>
  );
};

const initialState: OnboardingTasksState = [
  {
    id: 'open-bar',
    content: (
      <Instruction>
        Open CommandBar with{' '}
        <ExtraEmphasisText>{osControlKey()}+k</ExtraEmphasisText> or clicking{' '}
        <ExtraEmphasisText>Find anything</ExtraEmphasisText> in the top right.
      </Instruction>
    ),
    completed: false,
    completedTimestamp: null,
  },
  {
    id: 'navigate-to-pipeline',
    title: 'Navigation',
    content: (
      <div>
        <span>
          {`The list of actions you see in CommandBar are called "commands".
          Commands let users take different kinds of actions inside an app.`}
        </span>
        <Box sx={{ pt: 0.5, pb: 0.5 }}>
          The simplest commands are navigation commands: they let users teleport
          to where they need to go.
        </Box>
        <Instruction>
          Search and select the{' '}
          <ExtraEmphasisText>Go to pipeline</ExtraEmphasisText> command to
          navigate to the pipeline page.
        </Instruction>
      </div>
    ),
    completed: false,
    completedTimestamp: null,
  },
  {
    id: 'open-lead',
    title: 'Search',
    content: (
      <div>
        <span>
          {`Users can also search through content (aka "records") via CommandBar.
          Salesfarce has configured CommandBar to search through Leads and Events.`}
        </span>
        <Instruction>
          Open CommandBar,{' '}
          <ExtraEmphasisText>{`search for "Myspace"`}</ExtraEmphasisText>, and
          select it.
        </Instruction>
      </div>
    ),
    completed: false,
    completedTimestamp: null,
  },
  {
    id: 'update-lead-stage',
    title: 'Context-aware actions',
    content: (
      <div>
        <span>
          {`If you open CommandBar now, you'll see new context-aware commands. These
          are only available when a lead is open.`}
        </span>
        <Instruction>
          Open CommandBar and select the{' '}
          <ExtraEmphasisText>Change lead stage</ExtraEmphasisText>
          <span>{` command to change Myspace's stage to `}</span>
          <ExtraEmphasisText>Lost</ExtraEmphasisText>.
        </Instruction>
      </div>
    ),
    completed: false,
    completedTimestamp: null,
  },
  {
    id: 'spoonfeed-update-lead-stage',
    title: 'Context-aware actions',
    content:
      'Now try editing a few leads with and without CommandBar to feel the difference. Imagine you need to update 10 leads!',
    completed: false,
    completedTimestamp: null,
    allowNext: true,
  },
  {
    id: 'set-reminder',
    title: 'Shortcuts',
    content: (
      <div>
        <span>
          {`You can assign shortcuts to commands make workflows even faster. Users
          can always access commands through CommandBar, even if there's a
          shortcut attached.`}
        </span>
        <Instruction>
          With a lead open,{' '}
          <ExtraEmphasisText>
            {`make sure CommandBar is closed and type "r"`}
          </ExtraEmphasisText>{' '}
          <span>{`to set a reminder for next friday (just type "next friday"!).`}</span>
        </Instruction>
      </div>
    ),
    completed: false,
    completedTimestamp: null,
  },
  {
    id: 'complete-basics',
    title: 'Basics completed!',
    content:
      "You've got the basics down! You can explore the site to find new commands, or click next for a few extra quests.",
    completed: false,
    completedTimestamp: null,
    allowNext: true,
  },
  {
    id: 'add-helpdocs',
    title: 'Help Docs',
    content: (
      <div>
        You can connect your help center to make help docs searchable from
        CommandBar.
        <Instruction>
          Use the{' '}
          <ExtraEmphasisText>
            Read about: How to use the pipeline stages
          </ExtraEmphasisText>{' '}
          command to open a help doc that was connected to CommandBar.
        </Instruction>
      </div>
    ),
    completed: false,
    completedTimestamp: null,
  },
  {
    id: 'invite-teammate',
    title: 'Text inputs',
    content: (
      <div>
        As you saw, CommandBar commands can take user input. These inputs can
        take various types: selects, dates, etc. Try out a command that takes
        text as input.
        <Instruction>
          Open CommandBar and select the{' '}
          <ExtraEmphasisText>Invite a teammate</ExtraEmphasisText> command. You
          can set up short forms (like this) in CommandBar.
        </Instruction>
      </div>
    ),
    completed: false,
    completedTimestamp: null,
  },
  {
    id: 'switch-theme',
    title: 'Customize CommandBar with skins',
    content: (
      <div>
        <span>
          {`CommandBar can be customized with different skins to match your app. See
          CommandBar in a different skin by toggling the site's theme.`}
        </span>
        <Instruction>
          {`Switch the site's theme and open CommandBar. CommandBar can change
          colors if you want it to.`}
        </Instruction>
      </div>
    ),
    completed: false,
    allowNext: true,
    completedTimestamp: null,
  },
];

export const isCurrentTask = (task: OnboardingTask) => {
  return task.completed === false || isRecentlyCompleted(task);
};

export const isRecentlyCompleted = (task: OnboardingTask) => {
  const THRESHOLD = 1000 * 1;
  return (
    !!task.completedTimestamp &&
    new Date().getTime() - task.completedTimestamp < THRESHOLD
  );
};

const slice = createSlice({
  name: 'onboardingTasks',
  initialState,
  reducers: {
    completeTask: {
      reducer: (state, action: PayloadAction<{ id: string }>) => {
        const { id } = action.payload;

        const findCurrentIndex = state.findIndex(isCurrentTask);

        const index = state.findIndex((task) => task.id === id);

        if (index !== -1 && findCurrentIndex === index) {
          state[index].completed = true;
          state[index].completedTimestamp = new Date().getTime();
        }
      },
      prepare: (id: string) => {
        return { payload: { id } };
      },
    },
  },
});

export const getOnboardingTasks = (state: RootState) => {
  return state.onboardingTasks;
};

export const { reducer } = slice;
export const { completeTask } = slice.actions;

export default slice;
