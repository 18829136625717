import React, { createContext } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import type { User } from '../types/user';

interface State {
  user: User | null;
}

const fake_user = {
  id: '1',
  avatar: 'hello',
  email: 'foo@bar.com',
  name: 'FooBar',
};

interface AuthProviderProps {
  children: ReactNode;
}

const initialState: State = {
  user: fake_user,
};

const AuthContext = createContext<State>({
  ...initialState,
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;

  return (
    <AuthContext.Provider
      value={{
        ...initialState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
