import { arrayFromEntity } from '../../utils/entity';
import { RootState } from '../../store';

export const getCompanyStageSelector =
  (companyId: string) => (state: RootState) => {
    const stages = arrayFromEntity(state.stages.columns);

    for (const stage of stages) {
      if (stage.companyIds.includes(companyId)) {
        return stage.id;
      }
    }

    throw new Error(`Unable to get company ${companyId} stage id`);
  };

export const getStages = (state: RootState) =>
  arrayFromEntity(state.stages.columns);
