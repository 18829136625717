import { useState } from 'react';
import {
  Box,
  Container,
  Dialog,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { HubspotForm, HubspotFormContainer } from 'src/components/HubspotForm';
import styled from '@emotion/styled';

const LOCALSTORAGE_KEY = 'into.signup.viewed';

export const SIGNUP_SEARCH_PARAM_NAME = 'jasduf';
export const SIGNUP_SEARCH_PARAM_VALUE = '348dj';

const isSignUpFormVisible = () => {
  const params = new URLSearchParams(document.location.search);
  const searchParam = params.get(SIGNUP_SEARCH_PARAM_NAME);

  return !(searchParam && searchParam === SIGNUP_SEARCH_PARAM_VALUE);
};

const IntroModal = () => {
  const { enqueueSnackbar } = useSnackbar();

  const show = window.localStorage.getItem(LOCALSTORAGE_KEY) === null;
  const showSignUpForm = isSignUpFormVisible();
  const [open, setOpen] = useState(show);

  const closeModal = () => {
    setOpen(false);
    window.localStorage.setItem(LOCALSTORAGE_KEY, '1');
  };

  const handleModalClose = async () => {
    if (!isSignUpFormVisible()) {
      closeModal();
    }
  };

  const onFormSubmitHubspot = () => {
    closeModal();
    enqueueSnackbar('Successfully submitted!', { variant: 'success' });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleModalClose}
      style={{ zIndex: 1400, background: '#f4f5f7' }}
    >
      <Box
        sx={{
          backgroundColor: 'background.default',
          pt: 3,
          pb: 2,
          pr: 3,
          pl: 3,
        }}
      >
        <Container>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            mb={2}
          >
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Welcome to CommandBar
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Box mt={3}>
            <Typography color="textPrimary" variant="body1">
              <span>
                {` You've found Salesfarce, an imaginary CRM app. We've implemented CommandBar
                  inside Salesfarce to show you how CommandBar works in a realistic setting.`}
              </span>
            </Typography>
          </Box>

          {showSignUpForm && (
            <>
              <HubspotForm
                region={'na1'}
                portalId={'21959394'}
                formId={'df4ac63f-25c9-401d-a71e-0b9c8bb2e48f'}
                onSubmit={onFormSubmitHubspot}
                styledComponent={showcaseHubspotForm}
              ></HubspotForm>
            </>
          )}
        </Container>
      </Box>
    </Dialog>
  );
};

const showcaseHubspotForm = styled(HubspotFormContainer)`
  color: #172b4d;
  margin-top: 16px;

  form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    & * {
      font-family: -apple-system, Avenir Next, proxima-nova, Helvetica, Arial,
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji' !important;
    }
    fieldset {
      & > div {
      }

      label {
      }
      legend {
      }

      input[type='text'],
      input[type='email'],
      select {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        font-size: 0.825rem;
        line-height: 1.45rem;
      }
      ul {
        li {
        }
      }
    }

    .hs_submit {
      .actions {
        input {
        }
      }
    }
  }
`;

export default IntroModal;
