import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import type { Company } from '../../types/company';
import { entityFromArray } from '../../utils/entity';
import { Entity } from '../../types/entity';
import { moveCard } from '../stages/actions';
import { companiesList } from './companiesList';
import {
  bulkDeleteCompaniesById,
  createCompany,
  deleteCompanyById,
  updateCompany,
} from './actions';

interface CompaniesState {
  entity: Entity<Company>;
  labels: string[];
}

const initialState: CompaniesState = {
  entity: entityFromArray(companiesList),
  labels: ['Warm intro', 'Priority', 'Bad fit'],
};

const slice = createSlice({
  name: 'companies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCompany, (state, action) => {
        const { company } = action.payload;

        state.entity.byId[company.id] = company;

        if (!state.entity.allIds.includes(company.id)) {
          state.entity.allIds.push(company.id);
        }
      })
      .addCase(updateCompany, (state, action) => {
        const { company } = action.payload;

        if (state.entity.allIds.includes(company.id)) {
          state.entity.byId[company.id] = company;
        }
      })
      .addCase(deleteCompanyById, (state, action) => {
        const { companyId } = action.payload;

        delete state.entity.byId[companyId];
        state.entity.allIds = state.entity.allIds.filter(
          (id) => id !== companyId
        );
      })
      .addCase(bulkDeleteCompaniesById, (state, action) => {
        const { companyIds } = action.payload;

        companyIds.forEach((companyId) => {
          delete state.entity.byId[companyId];
        });
        state.entity.allIds = state.entity.allIds.filter(
          (id) => !companyIds.includes(id)
        );
      })
      .addCase(moveCard, (state, action) => {
        const { companyId, columnId } = action.payload;

        if (columnId && state.entity.allIds.includes(companyId)) {
          state.entity.byId[companyId].stageId = columnId;
        }
      })
      .addMatcher(isAnyOf(createCompany, updateCompany), (state, action) => {
        const { company } = action.payload;

        company.labels.forEach((label) => {
          if (!state.labels.includes(label)) {
            state.labels.push(label);
          }
        });
      })

      .addDefaultCase((state, _action) => state);
  },
});

export const { reducer } = slice;

export default slice;
