export const CURRENCIES = ['USD', 'GBP', 'EUR', 'JPY', 'BTC', 'DOGE'] as const;

export const CURRENCY_SYMBOLS: Record<Currency, string> = {
  USD: '$',
  GBP: '£',
  EUR: '€',
  JPY: '¥',
  BTC: '₿',
  DOGE: 'Ɖ ',
};

export type Currency = (typeof CURRENCIES)[number];
