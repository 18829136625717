import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from '../slices/calendar';
import { reducer as stagesReducer } from '../slices/stages/slice';
import { reducer as mailReducer } from '../slices/mail';
import { reducer as companiesReducer } from '../slices/companies/slice';
import { reducer as accountSettingsReducer } from '../slices/accountSettings';
import { reducer as integrationsReducer } from '../slices/integrations';
import { reducer as onboardingTasksReducer } from '../slices/onboardingTasks';

const rootReducer = combineReducers({
  companies: companiesReducer,
  accountSettings: accountSettingsReducer,
  calendar: calendarReducer,
  stages: stagesReducer,
  mail: mailReducer,
  integrations: integrationsReducer,
  onboardingTasks: onboardingTasksReducer,
});

export default rootReducer;
