import React from 'react';
import { Box } from '@material-ui/core';
import { SxProps } from '@material-ui/system';
import { toSvg } from 'jdenticon';

interface Props {
  str: string;
  size?: number;
  sx?: SxProps;
}

export const Avatar = ({ str, size = 42, sx }: Props) => {
  if (str.startsWith('http')) {
    return (
      <Box
        sx={{
          sx,
          width: size,
          height: size,
          borderRadius: size / 2,
          overflow: 'hidden',
          backgroundColor: (theme) => theme.palette.grey[200],
        }}
      >
        <img alt="avatar" src={str} width={size} height={size} />
      </Box>
    );
  }

  const svgString = toSvg(str, size, {
    lightness: {
      color: [0.47, 0.8],
      grayscale: [0.28, 0.71],
    },
    saturation: {
      color: 0.67,
      grayscale: 0.61,
    },
  });

  return (
    <Box
      sx={{
        sx,
        width: size,
        height: size,
        borderRadius: size / 2,
        overflow: 'hidden',
        backgroundColor: (theme) => theme.palette.grey[200],
      }}
      dangerouslySetInnerHTML={{ __html: svgString }}
    />
  );
};
