import {
  addDays,
  endOfDay,
  setHours,
  setMinutes,
  startOfDay,
  subDays,
} from 'date-fns';
import { CalendarEvent } from '../../types/calendar';

const now = new Date();

export const initialEvents: CalendarEvent[] = [
  {
    id: '5e8882e440f6322fa399eeb8',
    allDay: false,
    color: '#43a048',
    description: 'Inform about contract progress',
    end: setHours(setMinutes(subDays(now, 6), 0), 19),
    start: setHours(setMinutes(subDays(now, 6), 30), 17),
    title: 'Call Leslie Knope (Pawnee)',
  },
  {
    id: '5e8882eb5f8ec686220ff131',
    allDay: false,
    color: '#43a048',
    description: 'Review their new custom paper requests',
    end: setHours(setMinutes(addDays(now, 2), 30), 15),
    start: setHours(setMinutes(addDays(now, 2), 0), 12),
    title: 'Meet with Tony Stark',
  },
  {
    id: '5e8882f1f0c9216396e05a9b',
    allDay: false,
    color: '#43a048',
    description: 'Get ready to feast',
    end: setHours(setMinutes(addDays(now, 5), 0), 12),
    start: setHours(setMinutes(addDays(now, 5), 0), 8),
    title: 'Drive to Lehman Bros luncheon',
  },
  {
    id: '5e8882f6daf81eccfa40dee2',
    allDay: true,
    color: null,
    description:
      'Meet with team to discuss lead strategy, lead generation, and overall performance',
    end: startOfDay(subDays(now, 11)),
    start: endOfDay(subDays(now, 12)),
    title: 'End of quarter planning',
  },
  {
    id: '5e8882e440f6322fa399eeg6',
    allDay: false,
    color: '#43a048',
    description: 'Review new contract proposal',
    end: setHours(setMinutes(addDays(now, 10), 0), 15),
    start: setHours(setMinutes(addDays(now, 10), 0), 13),
    title: 'Meet Mr. Krabs for lunch',
  },
  {
    id: '420882e440f6322fa399eeg6',
    allDay: false,
    color: '#43a048',
    description: 'Draft new contract proposal',
    end: setHours(setMinutes(addDays(now, 10), 0), 19),
    start: setHours(setMinutes(addDays(now, 10), 0), 16),
    title: 'Brainstorm response on new contract proposal for Krusty Krab',
  },
  {
    id: '420882f6daf81eccfa40dee2',
    allDay: true,
    color: null,
    description: 'Hope someone picks up the slack of messing with Dwight',
    end: startOfDay(addDays(now, 9)),
    start: endOfDay(addDays(now, 7)),
    title: 'Vacation',
  },
];
