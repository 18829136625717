import { createAction } from '@reduxjs/toolkit';

export const moveCard = createAction(
  'stages/moveCard',
  function prepare(companyId: string, position: number, columnId?: string) {
    return {
      payload: {
        companyId,
        position,
        columnId,
      },
    };
  }
);
