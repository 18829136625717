import { StagesState } from './slice';
import { arrayFromEntity } from '../../utils/entity';

export const getCompanyStageId =
  (companyId: string) => (state: StagesState) => {
    const stages = arrayFromEntity(state.columns);

    for (const stage of stages) {
      if (stage.companyIds.includes(companyId)) {
        return stage.id;
      }
    }

    return undefined;
  };
