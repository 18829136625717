import React, { useEffect } from 'react';
import type { FC } from 'react';
import { useRoutes, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ThemeProvider } from '@material-ui/core';
import { BrowserView, MobileView } from 'react-device-detect';
import { init } from 'commandbar';

import './i18n';
import { identifyUser } from './identify';
import GlobalStyles from './components/GlobalStyles';
import RTL from './components/RTL';
import { gtmConfig } from './config';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routes from './routes';
import { createTheme } from './theme';
import { useDispatch, useSelector } from './store';
import { getCompaniesSelector } from './slices/companies/selectors';
import { getStages } from './slices/stages/selectors';
import IntroModal from './pages/IntroModal';
import { CURRENCIES, CURRENCY_SYMBOLS } from './types/currency';
import { THEMES } from './constants';
import { OnboardingChecklist } from './components/OnboardingChecklist';
import { completeTask } from './slices/onboardingTasks';
import MobileModal from './pages/MobileModal';
import initSegment from './utils/segment';

init('05f077f2');
identifyUser();

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings, saveSettings } = useSettings();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  useScrollReset();
  const dispatch = useDispatch();

  const companies = useSelector(getCompaniesSelector);
  const events = useSelector((state) => state.calendar.events);
  const stages = useSelector(getStages);
  const stagesEntity = useSelector((state) => state.stages.columns);
  const account = useSelector((state) => state.accountSettings);

  // need this so our pre-select on change stage command works
  const augStages = stages.map(({ id, name }) => ({
    augId: id,
    augName: name,
  }));

  // adapted code from Avatar.tsx
  // const getSvgString = (str: string) => {
  //   return toSvg(str, 42, {
  //     lightness: {
  //       color: [0.47, 0.8],
  //       grayscale: [0.28, 0.71],
  //     },
  //     saturation: {
  //       color: 0.67,
  //       grayscale: 0.61,
  //     },
  //   });
  // };

  const handleRouteChange = (newUrl) => {
    if (newUrl === '/leads/wo25pG-YkhOUiTycEze0m') {
      dispatch(completeTask('open-lead'));
    }
    if (newUrl === '/pipeline') {
      dispatch(completeTask('navigate-to-pipeline'));
    }
  };

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  useEffect(() => {
    initSegment();
  }, []);

  useEffect(() => {
    const routerFunc = (newUrl) => {
      navigate(newUrl);
      handleRouteChange(newUrl);
    };

    window.CommandBar.addRouter(routerFunc);
  }, []);

  useEffect(() => {
    window.CommandBar.addContext({
      stages: stages.map(({ id, name }) => ({ id, name })),
      activeCurrencies: companies.map(({ name, currency }) => ({
        id: `${name}-${currency}`,
        name: currency,
      })),
      activeStages: companies.map(({ name, stageId }) => ({
        id: `${name}-${stageId}`,
        name: stagesEntity.byId[stageId].name,
        value: stageId,
      })),
      companies: companies.map(
        ({
          id,
          name,
          stageId,
          contactEmail,
          contactName,
          notes,
          labels,
          currency,
          annualContractValue,
          reminder,
          logoURL,
        }) => ({
          id,
          name,
          stageId,
          contactEmail,
          contactName,
          notes,
          labels,
          annualContractValue,
          reminder,
          currency,
          label: name,
          description: `Contact: ${contactName}`,
          category: stagesEntity.byId[stageId].name,
          currencies: CURRENCIES.map((x) => ({ id: `${name}-${x}`, name: x })),
          stages: augStages.map(({ augId, augName }) => ({
            id: `${name}-${augId}`,
            value: augId,
            name: augName,
          })),
          icon: logoURL,
          // 'html': <HTMLElement> <div class = "flexArea">/n<div style=""
        })
      ),
      currencies: CURRENCIES.map((currency) => ({
        icon: CURRENCY_SYMBOLS[currency],
        label: currency,
      })),
      events,
      account,
    });
  }, [companies, events, stages, account]);

  useEffect(() => {
    const toggleTheme = () => {
      const newTheme =
        settings.theme === THEMES.DARK ? THEMES.LIGHT : THEMES.DARK;
      saveSettings({ ...settings, theme: newTheme });
    };
    window.CommandBar.addCallback('switchTheme', toggleTheme);

    if (settings.theme === THEMES.DARK) {
      window.CommandBar.setTheme('dark-theme');
    } else {
      window.CommandBar.setTheme('light-theme');
    }
  }, [settings.theme]);

  useEffect(() => {
    const inviteTeammate = (args: { email: string }) => {
      enqueueSnackbar(`Successfully added ${args.email} to your workspace`, {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        variant: 'success',
      });

      dispatch(completeTask('invite-teammate'));
    };

    const handleCommandBarEvent = (eventName, eventData) => {
      if (
        eventData.commandText === 'Set reminder for lead' &&
        eventData.shortcut
      ) {
        dispatch(completeTask('set-reminder'));
      }

      if (
        eventData.commandText === 'Read about: How to use the pipeline stages'
      ) {
        dispatch(completeTask('add-helpdocs'));
      }

      if (eventName === 'opened') {
        dispatch(completeTask('open-bar'));
        (window as any)?.hj('tagRecording', ['Bar opened']);
      }

      if (eventName === 'command_execution') {
        (window as any)?.hj('tagRecording', ['Command executed']);
      }
    };

    window.CommandBar.addCallback('inviteTeammate', inviteTeammate);
    window.CommandBar.addEventHandler(handleCommandBarEvent);
  }, []);

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <GlobalStyles />
        {content}
        <BrowserView>
          <IntroModal />
          <OnboardingChecklist />
        </BrowserView>
        <MobileView>
          <MobileModal />
        </MobileView>
      </RTL>
    </ThemeProvider>
  );
};

export default App;
