import React from 'react';
import { Box, Typography } from '@material-ui/core';

type Props = {
  keys: string[];
};

export const ShortcutTooltipContent = ({ keys }: Props) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    {keys.map((key, index, arr) => {
      return (
        <>
          <Typography
            variant="subtitle2"
            sx={{
              color: '#172b4d',
              backgroundColor: (theme) => theme.palette.grey[300],
              px: 0.5,
              py: 0,
              borderRadius: 0.5,
            }}
          >
            {key}
          </Typography>
          {index !== arr.length - 1 ? (
            <Typography
              variant="body2"
              sx={{
                color: '#fff',
                m: 0.5,
              }}
            >
              then
            </Typography>
          ) : null}
        </>
      );
    })}
  </Box>
);
